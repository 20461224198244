<template>
  <div class="row">
    <!-- TÍTULO E DESCRIÇÃO PARA USER -->
    <div class="col-12">
      <h4>{{ $t('FINANCEIRO.CONTAS_VINCULADAS') }}</h4>
    </div>

    <div class="col-12 w-100 text-right mb-2">
      <input-checkbox
        :label="$t('ACADEMICO.SELECIONAR_TODOS')"
        v-model="todosSelecionados"
        @change="selecaoTodos"
      />
    </div>
    <!-- TABELA DE RESULTADOS -->
    <div class="col-12">
      <b-table
        ref="selectableTable"
        :empty-text="$t('GERAL.NENHUM_REGISTRO')"
        :items="tabela.items"
        :fields="tabela.fields"
        head-variant="light"
        select-mode="multi"
        @row-selected="adicionarConta"
        show-empty
        responsive
        stacked="md"
        selectable
        striped
        hover
      >
        <template #cell(status)="item">
          <b-badge
            :variant="item.item.status == 'Ativo' ? 'primary' : 'warning'"
          >
            {{ item.item.status }}
          </b-badge>
        </template>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <i class="blue text-md fas fa-check-square"></i>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <i class="text-md far fa-square"></i>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import ContaService from '@/common/services/conta/conta.service';

// Components:
import { InputCheckbox } from '@/components/inputs';

export default {
  components: {
    InputCheckbox,
  },
  props: {
    form: { type: Object, default: Object },
  },
  data() {
    return {
      conta: {},
      todosSelecionados: false,
      tabela: {
        exibir: false,
        items: [],
        fields: [
          {
            key: 'banco',
            label: this.$t('GERAL.BANCO'),
            sortable: true,
          },
          {
            key: 'agencia',
            label: this.$t('GERAL.AGENCIA'),
            sortable: true,
          },
          {
            key: 'numeroConta',
            label: this.$t('GERAL.NUMERO_CONTA'),
            sortable: true,
          },
          {
            key: 'descricao',
            label: this.$t('GERAL.DESCRICAO'),
            sortable: true,
          },
          {
            key: 'status',
            label: this.$t('GERAL.STATUS'),
            sortable: true,
          },
          {
            key: 'selected',
            label: this.$t('GERAL.SELECIONADO'),
          },
        ],
      },
    };
  },
  mounted() {
    this.getContas();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodasSemPaginacaoOriginal()
        .then(({ data }) => {
          this.tabela.items = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNCOES TABELA
    adicionarConta(items) {
      this.form.contasIds = items;
    },

    selecaoTodos() {
      if (this.todosSelecionados)
        return this.$refs.selectableTable.selectAllRows();
      return this.$refs.selectableTable.clearSelected();
    },
  },
};
</script>
