<template>
  <topbar
    :titulo="$t('ACADEMICO.PERIODO_LETIVO')"
    :subtitulo="$t('ACADEMICO.PERIODO_LETIVO_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-md-4">
        <div />

        <b-button variant="secondary" @click="retornar()">
          {{ $t('GERAL.VOLTAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  props: {
    breadcrumb: { type: String, default: '' },
  },
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.ESTRUTURA_CEJ') },
        { titulo: this.$t('ACADEMICO.PERIODO_LETIVO') },
        { titulo: this.breadcrumb },
      ],
    };
  },
  methods: {
    retornar() {
      this.$router.push({ name: 'periodo-letivo' });
    },
  },
};
</script>
