import ApiService from "@/common/api/api.service";

const SegmentacaoService = {

    async criar(form) {
        return await ApiService.post(`Segmentacao/`, form);
    },
    async remover(id) {
        return await ApiService.delete(`Segmentacao/${id}`);
    },
    async buscarSegmentacoesPorAnoLetivoId(id) {
        return await ApiService.get(`Segmentacao/obter-por-anoLetivo`, {
            params: {
                anoLetivoId: id
            }
        });
    },
    async abrirSegmento(id) {
        return await ApiService.put(`Segmentacao/abrir-segmento?segmentacaoId=${id}`);
    },
    async fecharSegmento(id) {
        return await ApiService.put(`Segmentacao/fechar-segmento?segmentacaoId=${id}`);
    },

};

export default SegmentacaoService;