<template>
  <b-row>
    <b-col cols="12">
      <div class="mb-4">
        <titulo-formulario-periodo-letivo :breadcrumb="breadcrumb" />
        <b-tabs>
          <b-tab :title="`1 - ${$t('GERAL.DADOS_GERAIS')}`" class="pt-4" active>
            <dados-form :form="form" @refs="validarFormulario" />

            <dados-turma :form="form" @atualizar="preencherDados" />

            <dados-segmentacao :form="form" @atualizar="preencherDados" />

            <tabela-avaliacao :items="form.tipoAvaliacoes" />
          </b-tab>
          <b-tab
            v-if="!this.id"
            :title="`2 - ${$t('FINANCEIRO.PERIODO_FINANCEIRO')}`"
            class="pt-4"
          >
            <formulario-dados-periodo-financeiro
              :form="form.criarPeriodoFinanceiroRequest"
              @refs="capturarRefs"
            >
              <template #input>
                <div class="col-12 my-3">
                  <input-checkbox
                    v-model="form.flagCriarPeriodoFinanceiro"
                    type="boolean"
                    :label="$t('FINANCEIRO.CRIAR_PERIODO_FINANCEIRO')"
                    :placeholder="$t('ACADEMICO.CRIAR_PERIODO_FINANCEIRO')"
                  />
                </div>
              </template>
            </formulario-dados-periodo-financeiro>
            <formulario-contas-periodo-financeiro
              :form="form.criarPeriodoFinanceiroRequest"
              @refs="capturarRefs"
            />
          </b-tab>
        </b-tabs>
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button variant="primary" @click="salvar">{{
              $t('GERAL.SALVAR')
            }}</b-button>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import VerificacaoPreenchimento from '@/components/mixins/verificacao-preenchimento';
import { PeriodoLetivo } from '@/common/models';
import helpers from '@/common/utils/helpers';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

//services
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import TipoAvaliacaoService from '@/common/services/academico/tipoAvaliacao.service';

// components
import DadosForm from './components/DadosForm';
import DadosTurma from './components/DadosTurma';
import DadosSegmentacao from './components/DadosSegmentacao';
import TabelaAvaliacao from './components/TabelaAvaliacao';
import FormularioContasPeriodoFinanceiro from '@/views/financeiro/caixa/components/periodo-financeiro/modais/modal-criar-periodo-financeiro-por-lote/Conta';
import FormularioDadosPeriodoFinanceiro from '@/views/periodos-letivos/periodo-letivo/components/DadosPeriodoFinanceiro';
import { InputCheckbox } from '@/components/inputs';
import TituloFormularioPeriodoLetivo from '@/views/periodos-letivos/periodo-letivo/components/TituloFormularioPeriodoLetivo';

export default {
  name: 'Formulario',
  mixins: [VerificacaoPreenchimento],
  components: {
    TituloFormularioPeriodoLetivo,
    FormularioContasPeriodoFinanceiro,
    FormularioDadosPeriodoFinanceiro,
    DadosForm,
    DadosTurma,
    DadosSegmentacao,
    TabelaAvaliacao,
    InputCheckbox,
  },
  data() {
    return {
      form: new PeriodoLetivo({
        turmas: [],
        segmentacoes: [],
        tipoAvaliacoes: [],
        criarPeriodoFinanceiroRequest: {
          contasIds: [],
        },
      }),
      id: this.$route.params.id,
      breadcrumb: this.$route.params.id
        ? this.$t('GERAL.EDITAR')
        : this.$t('GERAL.CADASTRAR'),
      refsCamp: [],
    };
  },
  mounted() {
    this.id ? this.preencherDados() : this.getTipoAvaliacao();
  },

  methods: {
    salvar() {
      if (!helpers.validarFormulario(this.refsCamp)) return;

      if (this.form.dataInicioMatricula > this.form.dataFimMatricula) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t(
            'A data de Início Matrícula não pode ser superior à data Fim Matrícula'
          )
        );
        return;
      }
      if (this.form.dataInicio > this.form.dataFim) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t(
            'A data de Início Letivo não pode ser superior à data Fim Letivo'
          )
        );
        return;
      }
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.salvar({
        ...this.form,
        criarPeriodoFinanceiroRequest: {
          ...this.form.criarPeriodoFinanceiroRequest,
          contasIds: this.form?.criarPeriodoFinanceiroRequest?.contasIds.map((conta) => {
            return conta.id
          }
            
          ),
        },
      })
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.id
              ? this.$t('Período letivo atualizado com sucesso')
              : this.$t('Período letivo criado com sucesso')
          );
          this.$router.push({ name: 'periodo-letivo' });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    preencherDados() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.buscarPorId(this.id)
        .then(({ data }) => {
          this.form = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getTipoAvaliacao() {
      this.$store.dispatch(START_LOADING);
      TipoAvaliacaoService.obterTodos()
        .then(({ data }) => {
          this.form.tipoAvaliacoes = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    capturarRefs(refs) {
      this.$refs = { ...this.$refs, ...refs };
    },

    validarFormulario(refs) {
      this.refsCamp = refs;
    },
  },
};
</script>

<style>
.ajuste-fino {
  margin-top: 14px;
}
</style>