<template>
  <div>
    <h3 class="mt-3 title-3">Avaliações</h3>
    <b-table
      :fields="tabela.fields"
      :items="items"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      responsive="sm"
      class="mt-3"
      show-empty
      striped
      hover
    >
    </b-table>
  </div>
</template>
<script>
export default {
  name: 'TabelaAvaliacao',
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },

  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'descricao',
            label: 'Descrição',
            sortable: true,
          },
          {
            key: 'limiteAvaliacao',
            label: 'Quantidade máxima de lançamentos',
            sortable: true,
          },
        ],
      },
    };
  },
};
</script>
