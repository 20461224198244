<template>
  <div>
    <!-- status & data movimento -->
    <div class="row">
      <div class="col-12">
        <h4>{{ $t('GERAL.DADOS_GERAIS') }}</h4>
      </div>
      <slot name="input"></slot>
      <div class="col-12 col-md-4">
        <input-select-search
          ref="statusFinanceiro"
          v-model="formTemp.statusFinanceiro"
          label="FINANCEIRO.STATUS"
          :options="opcoes.status"
          required
        />
      </div>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
    retirarLegenda: { type: Boolean, default: false },
  },
  components: {
    InputSelectSearch,
  },
  data() {
    return {
      opcoes: {
        status: [],
      },
      formTemp: {},
    };
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.formTemp = this.form;

    // obtendo dados:
    this.getStatus();
  },
  methods: {
    getStatus() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-financeiro')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.status = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
