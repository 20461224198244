<template>
  <b-row>
    <b-col class="col-12 col-md-4">
      <input-text
        ref="ano"
        label="Ano"
        required
        v-model="form.ano"
        :maxLength="4"
        :showLengthLimit="false"
        type="number"
      />
    </b-col>

    <b-col class="col-12 col-md-4">
      <input-date-only-btn
        ref="inicioLetivo"
        label="Início letivo"
        required
        v-model="form.dataInicio"
      />
    </b-col>

    <b-col class="col-12 col-md-4">
      <input-date-only-btn
        ref="fimLetivo"
        label="Fim letivo"
        required
        v-model="form.dataFim"
      />
    </b-col>

    <b-col class="col-12 col-md-4">
      <input-date-only-btn
        ref="inicioMatricula"
        label="Início matrícula"
        required
        v-model="form.dataInicioMatricula"
      />
    </b-col>

    <b-col class="col-12 col-md-4">
      <input-date-only-btn
        ref="fimMatricula"
        label="Fim matrícula"
        required
        v-model="form.dataFimMatricula"
      />
    </b-col>

    <b-col class="col-12 col-md-4">
      <input-select
        :options="opcoes.anosLetivos"
        v-model="form.anoLetivoAntecessorId"
        valueField="id"
        textField="ano"
        label="Período letivo antecessor"
        placeholder="Selecione ou digite"
        :loading="loadingAnosLetivos"
      />
    </b-col>

    <b-col class="col-12 col-md-4">
      <input-select
        ref="statusLiberacao"
        :options="opcoes.statusLiberacao"
        v-model="form.statusLiberacao"
        valueField="id"
        textField="descricao"
        label="Status"
        placeholder="Selecione ou digite"
        required
        :loading="loadingStatusLiberacao"
      />
    </b-col>
  </b-row>
</template>

<script>
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import mensagem from '@/common/utils/mensagem';

import { InputText, InputDateOnlyBtn, InputSelect } from '@/components/inputs';

export default {
  components: {
    InputText,
    InputDateOnlyBtn,
    InputSelect,
  },
  props: {
    form: { type: Object, default: Object },
  },
  mounted() {
    this.getStatusLiberacao();
    this.getAnosLetivo();
    this.capturarRefs(this.$refs);
  },
  data() {
    return {
      opcoes: {
        anosLetivos: [],
        statusLiberacao: [],
      },
      refs: [],
      loadingAnosLetivos: false,
      loadingStatusLiberacao: false,
    };
  },
  methods: {
    getAnosLetivo() {
      this.loadingAnosLetivos = true;
      AnoLetivoService.listar()
        .then(({ data }) => {
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingAnosLetivos = false;
        });
    },

    getStatusLiberacao() {
      this.loadingStatusLiberacao = true;
      EnumeradoresService.listar('status-liberacao')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));

          this.opcoes.statusLiberacao = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingStatusLiberacao = false;
        });
    },

    capturarRefs(refs) {
      this.refs = refs;
      this.$emit('refs', this.refs);
    },
  },
};
</script>
