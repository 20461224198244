<template>
  <b-col cols="12">
    <h3 class="mt-3 title-3">Turmas</h3>
    <b-row class="d-flex align-items-center">
      <b-col class="col-12 col-md-4 col-lg-3 col-xl-2 alinharValidacao">
        <input-select-search
          ref="cursoId"
          :label="$t(`ACADEMICO.CURSO`)"
          :options="opcoes.cursos"
          v-model="formCursos"
          :required="requerido"
          :loading="loadingCursos"
        />
      </b-col>

      <b-col class="col-12 col-md-4 col-lg-3 col-xl-2 alinharValidacao">
        <input-select-search
          ref="turmaId"
          :label="$t(`ACADEMICO.TURMA`)"
          :options="opcoes.turmas"
          v-model="formTurmas"
          :isDisabled="desabilitar.turma"
          :required="requerido"
        />
      </b-col>
      <b-col class="alinharValidacao ajuste-btn">
        <b-button variant="primary" @click="getTurmaId">Adicionar</b-button>
      </b-col>
    </b-row>
    <tabela-turmas :items="form.turmas" @excluir="excluir" />
  </b-col>
</template>
<script>
import CursoService from '@/common/services/academico/curso.service';
import TurmaService from '@/common/services/academico/turma.service';
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';

import TabelaTurmas from './TabelaTurmas.vue';
import VerificacaoPreenchimento from '@/components/mixins/verificacao-preenchimento';

import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

import { InputSelectSearch } from '@/components/inputs';
import helpers from '@/common/utils/helpers';

export default {
  mixins: [VerificacaoPreenchimento],
  components: {
    InputSelectSearch,
    TabelaTurmas,
  },
  props: {
    form: { type: Object, default: Object },
  },
  mounted() {
    this.getCursos();
  },
  watch: {
    formCursos: 'getTurmas',
  },
  data() {
    return {
      id: this.$route.params.id,
      formCursos: '',
      formTurmas: '',
      requerido: true,
      desabilitar: {
        turma: true,
      },
      opcoes: {
        cursos: [],
        turmas: [],
      },
      loadingCursos: false,
    };
  },
  methods: {
    getCursos() {
      this.loadingCursos = true;
      CursoService.listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingCursos = false;
        });
    },

    getTurmas() {
      if (this.formCursos) {
        this.$store.dispatch(START_LOADING);
        TurmaService.buscarPorCursoId(this.formCursos)
          .then(({ data }) => {
            data = data.map((row) => ({
              ...data,
              text: row.descricao,
              value: row.id,
            }));
            this.opcoes.turmas = data;
            this.habilitarCampo('turma');
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },

    getTurmaId() {
      this.requerido = true;

      if (!this.validarFormulario(this.$refs)) return;

      if (this.formTurmas !== null) {
        this.$store.dispatch(START_LOADING);
        TurmaService.buscarPorId(this.formTurmas)
          .then(({ data }) => {
            this.adicionarTurma(data);
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },

    adicionarTurma(data) {
      if (this.id) {
        this.$store.dispatch(START_LOADING);
        AnoLetivoService.adicionarTurma({
          turmaId: data.id,
          anoLetivoId: this.id,
        })
          .then(() => {
            mensagem.sucesso(
              this.$t('GERAL.SUCESSO'),
              this.$t('Turma adicionada com sucesso')
            );
            this.$emit('atualizar');
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
        return;
      }
      this.form.turmas.push(data);
      this.limparCampo();
    },

    limparCampo() {
      this.formCursos = null;
      this.formTurmas = null;
      this.opcoes.turmas = [];
      this.desabilitar.turma = true;
      this.requerido = false;
    },

    validarFormulario(refs) {
      return helpers.validarFormulario(refs);
    },

    habilitarCampo(key) {
      this.desabilitar[key] = false;
    },

    removerBack(id) {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.removerTurma({ turmaId: id, anoLetivoId: this.id })
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('Turma removida com sucesso')
          );
          this.$emit('atualizar');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    excluir(item) {
      mensagem
        .confirmacao(null, this.$t('Deseja remover turma ?'))
        .then(async (result) => {
          if (result.value) {
            if (this.id) {
              this.removerBack(item.item.id);
              return;
            }
            this.form.turmas.splice(item.index, 1);
          }
        });
    },
  },
};
</script>
<style>
.ajuste-btn {
  margin-top: 28px;
}
</style>
