<template>
  <div>
    <b-table
      :fields="tabela.fields"
      :items="items"
      class="mt-3"
      show-empty
      responsive="sm"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      striped
      hover
    >
      <template #cell(acoes)="item">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <b-dropdown-item @click="excluir(item)">
              {{ $t('GERAL.EXCLUIR') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import helpers from '@/common/utils/helpers';
export default {
  name: 'TabelaPeriodoLetivo',
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },

  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'descricao',
            label: 'Descrição',
            sortable: true,
          },
          {
            key: 'statusAberturaDescricao',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'lancamentoNota',
            label: 'Nota',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarBooleanSimNao(value);
            },
          },
          {
            key: 'lancamentoFrequencia',
            label: 'Frequência',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarBooleanSimNao(value);
            },
          },
          {
            key: 'lancamentoRecuperacao',
            label: 'Avaliação final',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarBooleanSimNao(value);
            },
          },
          {
            key: 'lancamentoRecuperacaoSegmentacao',
            label: 'Recuperação p/ segmentação',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarBooleanSimNao(value);
            },
          },
          {
            key: 'dataInicio',
            label: 'Data início',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarDataBr(value);
            },
          },
          {
            key: 'dataFim',
            label: 'Data fim',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarDataBr(value);
            },
          },
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          },
        ],
      },
    };
  },
  methods: {
    excluir(item) {
      this.$emit('excluir', item);
    },
  },
};
</script>
