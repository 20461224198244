<template>
  <b-row>
    <!-- Segmentacao -->
    <b-col cols="12">
      <h3 class="mt-3 title-3">Segmentação</h3>
      <b-row class="d-flex align-items-center">
        <b-col class="col-12 col-md-4 col-lg-4 col-xl-2 alinharValidacao">
          <input-text
            required
            ref="descricao"
            v-model="formSegmentacao.descricao"
            label="Descrição"
          />
        </b-col>

        <b-col class="col-12 col-md-4 alinharValidacao">
          <input-date-only-btn
            ref="dataInicioSegmentacao"
            label="Data início"
            required
            v-model="formSegmentacao.dataInicio"
          />
        </b-col>

        <b-col class="col-12 col-md-4 alinharValidacao">
          <input-date-only-btn
            ref="dataFimSegmentacao"
            label="Data fim"
            required
            v-model="formSegmentacao.dataFim"
          />
        </b-col>

        <b-col class="col-12 col-md-2 alinharValidacao">
          <input-select-search
            ref="statusAbertura"
            :label="'Status'"
            :options="opcoes.statusAbertura"
            v-model="formSegmentacao.statusAbertura"
            required
            :loading="loadingStatusAbertura"
          />
        </b-col>

        <b-col class="col-12 col-md-2">
          <input-checkbox
            ref="lancamentoNota"
            v-model="formSegmentacao.lancamentoNota"
            label="Nota"
          />
        </b-col>

        <b-col class="col-12 col-md-2">
          <input-checkbox
            ref="lancamentoFrequencia"
            v-model="formSegmentacao.lancamentoFrequencia"
            label="Frequência"
          />
        </b-col>

        <b-col class="col-12 col-md-2">
          <input-checkbox
            ref="lancamentoRecuperacao"
            v-model="formSegmentacao.lancamentoRecuperacao"
            label="Avaliação Final"
          />
        </b-col>
        <b-col class="col-12 col-md-3">
          <input-checkbox
            ref="LancamentoRecuperacaoSegmentacao"
            v-model="formSegmentacao.lancamentoRecuperacaoSegmentacao"
            label="Recuperação segmentação"
          />
        </b-col>
        <b-col class="col-12 col-md-4 col-lg-3 col-xl-2">
          <b-button class="mt-2" variant="primary" @click="adicionarSegmentacao"
            >Adicionar</b-button
          >
        </b-col>
      </b-row>

      <tabela-segmentacao :items="form.segmentacoes" @excluir="excluir" />
    </b-col>
  </b-row>
</template>

<script>
import TabelaSegmentacao from './TabelaSegmentacao.vue';
import SegmentacaoService from '@/common/services/anoLetivo/segmentacao.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

import { Segmentacao } from '@/common/models';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

import {
  InputText,
  InputDateOnlyBtn,
  InputCheckbox,
  InputSelectSearch,
} from '@/components/inputs';
import helpers from '@/common/utils/helpers';

export default {
  components: {
    InputText,
    InputSelectSearch,
    TabelaSegmentacao,
    InputDateOnlyBtn,
    InputCheckbox,
  },
  props: {
    form: { type: Object, default: Object },
  },
  mounted() {
    this.getStatusAbertura();
  },
  data() {
    return {
      formSegmentacao: new Segmentacao({}),
      id: this.$route.params.id,
      opcoes: {
        statusAbertura: [],
      },
      loadingStatusAbertura: false,
    };
  },
  methods: {
    adicionarSegmentacao() {
      if (!this.validarFormulario(this.$refs)) return;

      if (!this.id) {
        const resultado = this.opcoes.statusAbertura.filter(
          ({ id }) => id == this.formSegmentacao.statusAbertura
        );
        this.formSegmentacao.statusAberturaDescricao = resultado[0].descricao;
        this.form.segmentacoes.push(this.formSegmentacao);
        this.formSegmentacao = new Segmentacao({});
        helpers.resetarValidacao(this.$refs);
        return;
      }
      this.adicionar();
      this.formSegmentacao = new Segmentacao({});
      helpers.resetarValidacao(this.$refs);
    },

    adicionar() {
      this.$store.dispatch(START_LOADING);
      SegmentacaoService.criar({
        ...this.formSegmentacao,
        anoLetivoId: this.id,
      })
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('Segmentação adicionada com sucesso')
          );
          this.$emit('atualizar');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    removerBack(id) {
      this.$store.dispatch(START_LOADING);
      SegmentacaoService.remover(id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('Segmentação excluída com sucesso')
          );
          this.$emit('atualizar');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    excluir(item) {
      mensagem
        .confirmacao(null, this.$t('Deseja remover segmentação ?'))
        .then(async (result) => {
          if (result.value) {
            if (this.id) {
              this.removerBack(item.item.id);
              return;
            }
            this.form.segmentacoes.splice(item.index, 1);
          }
        });
    },

    validarFormulario(refs) {
      if (this.formSegmentacao.dataFim < this.formSegmentacao.dataInicio) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('Em Segmentação: ') + this.$t('GERAL.DATA_INICIO_MENOR')
        );
        return;
      }
      return helpers.validarFormulario(refs);
    },

    getStatusAbertura() {
      this.loadingStatusAbertura = true;
      EnumeradoresService.listar('status-abertura')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));

          this.opcoes.statusAbertura = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingStatusAbertura = false;
        });
    },
  },
};
</script>

<style>
.title-3 {
  font-size: 22px;
}
.alinharValidacao {
  align-self: start;
}
</style>
